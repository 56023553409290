/* Taille de police */
.sFont {
    font-size: 10px;
}
.smFont {
    font-size: 12px;
}

.mFont {
    font-size: 13px;
}

.bFont {
    font-size: 14px;
}

.lFont {
    font-size: 16px;
}
.xlFont {
    font-size: 20px;
}
.xxlFont {
    font-size: 24px;
}
.lFont-imp {
    font-size: 16px !important;
}
.xlFont-imp {
    font-size: 20px !important;
}

.font-italic {
    font-style: italic;
}

/* Flexbox */
.fx-r {
    display: flex;
    flex-direction: row;
}

.fx-c {
    display: flex;
    flex-direction: column;
}

.fx-nw {
    flex-wrap: nowrap;
}

.fx-w {
    flex-wrap: wrap;
}

.fx-justify-fs {
    justify-content: flex-start;
}

.fx-justify-fe {
    justify-content: flex-end;
}

.fx-justify-c {
    justify-content: center;
}

.fx-justify-sb {
    justify-content: space-between;
}

.fx-justify-sa {
    justify-content: space-around;
}

.fx-justify-se {
    justify-content: space-evenly;
}

.fx-items-fs {
    align-items: flex-start;
}

.fx-items-fe {
    align-items: flex-end;
}

.fx-items-c {
    align-items: center;
}

.fx-items-bl {
    align-items: baseline;
}

.fx-items-st {
    align-items: stretch;
}

.fx-content-fs {
    align-content: flex-start;
}

.fx-content-fe {
    align-content: flex-end;
}

.fx-content-c {
    align-content: center;
}

.fx-content-sa {
    align-content: space-around;
}

.fx-content-sb {
    align-content: space-between;
}

.fx-content-se {
    align-content: space-evenly;
}

.fx-fg-0 {
    flex-grow: 0;
}

.fx-fg-half {
    flex-grow: 1/2;
}

.fx-fg-1 {
    flex-grow: 1;
}

.fx-fg-2 {
    flex-grow: 2;
}

.fx-fs-0 {
    flex-shrink: 0;
}

.fx-fs-1 {
    flex-shrink: 1;
}

.txt-align-c {
    text-align: center;
}

/* Padding */
.pad-0-imp {
    padding: 0px !important;
}
.pad-3px-imp {
    padding: 3px !important;
}
.pad-5px-imp {
    padding: 5px !important;
}
.pad-10px-imp {
    padding: 10px !important;
}
.pad-r-3-imp {
    padding-right: 3px !important;
}
.pad-l-3-imp {
    padding-left: 3px !important;
}
.pad-1px {
    padding: 1px;
}
.pad-2px {
    padding: 2px;
}
.pad-3px {
    padding: 3px;
}
.pad-5px {
    padding: 5px;
}
.pad-10px {
    padding: 10px;
}
.pad-15px {
    padding: 15px;
}
.pad-20px {
    padding: 20px;
}
.pad-b-5px {
    padding-bottom: 5px;
}
.pad-b-10px {
    padding-bottom: 10px;
}
.pad-b-15px {
    padding-bottom: 15px;
}
.pad-l-3px {
    padding-left: 5px;
}
.pad-l-5px {
    padding-left: 5px;
}
.pad-l-10px {
    padding-left: 10px;
}
.pad-l-15px {
    padding-left: 15px;
}
.pad-l-20px {
    padding-left: 20px;
}
.pad-r-3px {
    padding-right: 3px;
}
.pad-r-5px {
    padding-right: 5px;
}
.pad-r-10px {
    padding-right: 10px;
}
.pad-r-15px {
    padding-right: 15px;
}
.pad-r-20px {
    padding-right: 20px;
}
.pad-t-3px {
    padding-top: 3px;
}
.pad-t-5px {
    padding-top: 5px;
}
.pad-t-10px {
    padding-top: 10px;
}
.pad-t-15px {
    padding-top: 15px;
}

/* Margin */
.m--3px-imp {
    margin: -3px !important;
}
.m--5px-imp {
    margin: -5px !important;
}
.m-0 {
    margin: 0;
}
.m-3px {
    margin: 3px;
}
.m-5px {
    margin: 5px;
}
.m-10px {
    margin: 10px;
}
.m-15px {
    margin: 15px;
}
.m-20px {
    margin: 20px;
}
.m-b-5px {
    margin-bottom: 5px;
}
.m-b-10px {
    margin-bottom: 10px;
}
.m-b-15px {
    margin-bottom: 15px;
}
.m-l-3px {
    margin-left: 3px;
}
.m-l-5px {
    margin-left: 5px;
}
.m-l-10px {
    margin-left: 10px;
}
.m-l-15px {
    margin-left: 15px;
}
.m-r-3px {
    margin-right: 3px;
}
.m-r-5px {
    margin-right: 5px;
}
.m-r-10px {
    margin-right: 10px;
}
.m-r-15px {
    margin-right: 15px;
}
.m-t-5px {
    margin-top: 5px;
}
.m-t-10px {
    margin-top: 10px;
}
.m-t-15px {
    margin-top: 15px;
}

/* Z-index */
.z-idx-1 {
    z-index: 1 !important;
}
.z-idx-2 {
    z-index: 2 !important;
}
.z-idx-3 {
    z-index: 3 !important;
}
.z-idx-4 {
    z-index: 4 !important;
}
.z-idx-1111 {
    z-index: 1111 !important;
}

/* Position */
.pos-sy {
    position: sticky;
}
.pos-ab {
    position: absolute;
}
.pos-re {
    position: relative;
}
.pos-fi {
    position: fixed;
}

/* Largeur */
.w-2px {
    width: 2px;
}
.w-8px {
    width: 8px;
}
.w-25px-imp {
    width: 25px !important;
}
.w-40px-imp {
    width: 40px !important;
}
.w-190px-imp {
    width: 190px !important;
}
.w-200px-imp {
    width: 200px !important;
}
.w-20px {
    width: 20px;
}
.w-25px {
    width: 25px;
}
.w-30px {
    width: 30px;
}
.w-35px {
    width: 35px;
}
.w-50px {
    width: 50px;
}
.w-60px {
    width: 60px;
}
.w-75px {
    width: 75px;
}
.w-80px {
    width: 80px;
}
.w-100px {
    width: 100px;
}
.w-150px {
    width: 150px;
}
.w-200px {
    width: 200px;
}
.w-250px {
    width: 250px;
}
.w-280px {
    width: 280px;
}
.w-300px {
    width: 300px;
}
.w-400px {
    width: 400px;
}
.w-100vh {
    width: 100vw;
}
.w-25 {
    width: 25%;
}
.w-30 {
    width: 30%;
}
.w-33 {
    width: 33%;
}
.w-50 {
    width: 50%;
}
.w-75 {
    width: 75%;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}
.min-w-10px-imp {
    min-width: 10px !important;
}
.min-w-10px {
    min-width: 10px;
}
.min-w-50px {
    min-width: 50px;
}
.min-w-100px {
    min-width: 100px;
}
.min-w-150px {
    min-width: 150px;
}
.min-w-200px {
    min-width: 200px;
}
.min-w-250px {
    min-width: 250px;
}
.min-w-300px {
    min-width: 300px;
}
.min-w-400px {
    min-width: 400px;
}
.max-w-50px {
    max-width: 50px;
}
.max-w-100px {
    max-width: 100px;
}
.max-w-150px {
    max-width: 150px;
}
.max-w-200px {
    max-width: 200px;
}
.max-w-250px {
    max-width: 250px;
}
.max-w-300px {
    max-width: 300px;
}
.max-w-400px {
    max-width: 400px;
}

/* Hauteur */
.h-auto {
    height: auto;
}
.h-1px {
    height: 1px;
}
.h-25px-imp {
    height: 25px !important;
}
.h-40px-imp {
    height: 40px !important;
}
.h-10px {
    height: 10px;
}
.h-20px {
    height: 20px;
}
.h-25px {
    height: 25px;
}
.h-30px {
    height: 30px;
}
.h-40px {
    height: 40px;
}
.h-50px {
    height: 50px;
}
.h-100px {
    height: 100px;
}
.h-150px {
    height: 150px;
}
.h-200px {
    height: 200px;
}
.h-250px {
    height: 250px;
}
.h-300px {
    height: 300px;
}
.h-400px {
    height: 300px;
}
.h-0 {
    height: 0;
}
.h-50 {
    height: 50%;
}
.h-75 {
    height: 75%;
}
.h-100 {
    height: 100%;
}
.h-80vh {
    height: 80vh;
}
.h-100vh {
    height: 100vh;
}
.min-h-0-imp {
    min-height: 0px !important;
}
.min-h-25px {
    min-height: 25px;
}
.min-h-50px {
    min-height: 50px;
}
.min-h-100px {
    min-height: 100px;
}
.min-h-150px {
    min-height: 150px;
}
.min-h-200px {
    min-height: 200px;
}
.min-h-250px {
    min-height: 250px;
}
.min-h-300px {
    min-height: 300px;
}
.min-h-50 {
    min-height: 50%;
}
.min-h-75 {
    min-height: 75%;
}
.min-h-100 {
    min-height: 100%;
}
.max-h-50px {
    max-height: 50px;
}
.max-h-100px {
    max-height: 100px;
}
.max-h-150px {
    max-height: 150px;
}
.max-h-200px {
    max-height: 200px;
}
.max-h-250px {
    max-height: 250px;
}
.max-h-300px {
    max-height: 300px;
}
.max-h-400px {
    max-height: 400px;
}
.max-h-500px {
    max-height: 500px;
}
.max-h-50 {
    max-height: 50%;
}
.max-h-75 {
    max-height: 75%;
}
.max-h-100 {
    max-height: 100%;
}

/* Overflow */
.of-x-auto {
    overflow-x: auto;
}
.of-x-hidden {
    overflow-x: hidden;
}
.of-x-scroll {
    overflow-x: scroll;
}
.of-x-visible {
    overflow-x: visible;
}
.of-y-auto {
    overflow-y: auto;
}
.of-y-hidden {
    overflow-y: hidden;
}
.of-y-scroll {
    overflow-y: scroll;
}
.of-y-visible {
    overflow-y: visible;
}

/* Positionnement */
.top--13 {
    top: -13px;
}
.top--5 {
    top: -5px;
}
.top-0 {
    top: 0;
}
.top-10px {
    top: 10px;
}
.top-20px {
    top: 20px;
}
.top-30px {
    top: 30px;
}
.top-40px {
    top: 40px;
}
.top-50px {
    top: 50px;
}

.left-0 {
    left: 0;
}
.left-10px {
    left: 10px;
}
.left-20px {
    left: 20px;
}
.left-30px {
    left: 30px;
}
.left-40px {
    left: 40px;
}
.left-50px {
    left: 50px;
}
.left-230px {
    left: 230px;
}
.left-290px {
    left: 290px;
}
.left-310px {
    left: 310px;
}
.left-430px {
    left: 430px;
}
.left-50 {
    left: 50%;
}

.bottom-0 {
    bottom: 0;
}
.bottom-10px {
    bottom: 10px;
}
.bottom-20px {
    bottom: 20px;
}
.bottom-30px {
    bottom: 30px;
}
.bottom-40px {
    bottom: 40px;
}
.bottom-50px {
    bottom: 50px;
}

.right-0 {
    right: 0;
}
.right-10px {
    right: 10px;
}
.right-20px {
    right: 20px;
}
.right-30px {
    right: 30px;
}
.right-40px {
    right: 40px;
}
.right-50px {
    right: 50px;
}

/* Couleurs de fond */
.bg-red {
    background-color: red;
}
.bg-green {
    background-color: green;
}
.bg-yellow {
    background-color: yellow;
}
.bg-blue {
    background-color: blue;
}
.bg-euro {
    background-color: #003399;
}
.bg-white {
    background-color: white;
}
.bg-whitesmoke {
    background-color: whitesmoke;
}
.bg-gray {
    background-color: gray;
}
.bg-black {
    background-color: black;
}
.bg-lightblack {
    background-color: #333333;
}
.bg-lightgray {
    background-color: lightgray;
}
.bg-transparent {
    background-color: transparent;
}

.bg-black-imp {
    background-color: black !important;
}

/* Bords */
.border {
    border: 1px solid black;
}
.border-0 {
    border: 0px;
}
.border-b-1px {
    border-bottom: 1px solid;
}
.border-t-1px {
    border-top: 1px solid;
}
.border-l-1px {
    border-left: 1px solid;
}
.border-r-1px {
    border-right: 1px solid;
}
.border-gray {
    border-color: lightslategray;
}
.border-lightgray {
    border-color: lightgray;
}
.border-black {
    border-color: black;
}
.border-radius-5px {
    border-radius: 5px;
}
.border-radius-10px {
    border-radius: 10px;
}
.border-radius-15px {
    border-radius: 15px;
}

/* Couleur du texte */
.text-red {
    color: red;
}
.text-darkred {
    color: darkred;
}
.text-green {
    color: green;
}
.text-yellow {
    color: yellow;
}
.text-blue {
    color: blue;
}
.text-white {
    color: white;
}
.text-gray {
    color: gray;
}
.text-black {
    color: black;
}
.text-black-imp {
    color: black !important;
}

.text-decoration-ul {
    text-decoration: underline;
}

.font-w-400 {
    font-weight: 400;
}
.font-w-500 {
    font-weight: 500;
}
.font-w-600 {
    font-weight: 600;
}
.font-w-700 {
    font-weight: 700;
}
.font-w-800 {
    font-weight: 800;
}

/* Divers */
.plan-date {
    font-size: 20px;
    font-weight: 800;
}

.one-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: table-cell;
    vertical-align: middle;
}

.one-line-container {
    display: table;
    table-layout: fixed;
    width: 100%;
    vertical-align: middle;
}

.outline-black {
    outline-color: black;
}

.accent-black {
    accent-color: black;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.outline-orange {
    outline-color: orange;
}
.outline-crimson {
    outline-color: crimson;
}

.hover-black:hover {
    outline: 1px solid black;
}

.outline-1px {
    outline-width: 1px;
    outline-style: solid;
}

.focus-outline:focus {
    outline: 2px solid black;
}

::-moz-selection {
    background-color: rgba(50, 50, 50, 1);
    color: #fff;
}

::selection {
    background-color: rgba(50, 50, 50, 1);
    color: #fff;
}

.wrapper {
    opacity: 0;
    visibility: collapse;
    transition:
        max-height 0.3s ease,
        height 0.3s ease,
        visibility 0.3s ease;
    overflow: hidden;
    max-height: 0;
}

.wrapper.open {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
}


.hidden-xs {
    display: none;
}
.display-xs {
    display: flex;
}

@media (min-width: 900px) {
    .hidden-xs {
        display: flex;
    }
    .display-xs {
        display: none;
    }
}

/*------------------------------------------------------------------------------*/
/*                                    TABLES                                    */
/*------------------------------------------------------------------------------*/

html, body, #root {
    height: 100%;
}
main {
    height: 100%;
}


.table-container {
    width: 100%;
    max-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 5px;
    

    /*position: relative;
    z-index: 1;*/

    table {
        border-spacing: 0;
        width: 100%;
        table-layout: fixed;
        border-radius: 5px;
        border-collapse: collapse;
        box-sizing: border-box;
        background-color: white;
    }
    td,
    th {
        border: none !important;
        margin: 0;
        padding: 0;
    }

    table img {
        box-sizing: content-box;
    }

    tbody {
        z-index: 2;
    }

    td {
        border: 0px solid #dddddd;
        min-height: 1px;
        height: 1px;
        padding: 5px 3px 5px 3px;
        word-wrap: break-word;
        text-align: left;
        vertical-align: top;
        font-size: 13px;
    }

    th {
        background-color: black;
        color: white;
        height: 1px;
        min-height: 1px;
        word-wrap: break-word;
        text-align: left;
        top: 0px;
        position: sticky;
        z-index: 3;
        font-size: 14px;
        padding: 5px 0px 5px 3px;
        text-transform: uppercase;
        height: 40px;
    }

    th > .source {
        color: cyan;
    }

    th > .dest {
        color: lightgreen;
    }

    tr:nth-child(even) td {
        background-color: #fcfcfc;
    }

    tr:nth-child(odd) td {
        background-color: #f0f0f0;
    }

    tr:hover td,
    tr:hover .only-over {
        background-color: #e3e3e3;
    }

    tr {
        line-height: 1.4em;
    }
}

.droppable {
    display: inline;
    width: 100%;
}

.draggable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    background-color: lightgrey;
}

.draggable-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
}

.draggable-children {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
}

/*------------------------------------------------------------------------------*/
/*                                    INPUT                                     */
/*------------------------------------------------------------------------------*/

input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: white;
    outline: 1px solid black;
    border-radius: 3px;
    height: 24px;
    width: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    cursor: pointer;
}

.checkbox-star:checked {
    &:before {
        content: '\f005' !important;
        color: gold !important;
    }
}

input[type='checkbox']:checked {
    background: black;
    position: relative;
    &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 700;
        content: '\f00c';
        color: lightgreen;
        font-size: 0.8rem;
    }
}

input[type='checkbox']:disabled {
    background: transparent;
    outline: 1px solid lightgray;
    &:before {
    }
    cursor: not-allowed;
}

input {
    font-family: 'SF Pro Text', 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif';
}

input[type='text']:focus {
    outline-color: black;
}

textarea:focus {
    outline-color: black;
}
.autocomplete-focused .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black;
}
.autocomplete-warning {
    border-color: darkorange !important;
}

.autocomplete-infos {
    border-color: #8888dd !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/*------------------------------------------------------------------------------*/
/*                           LOADING SCREEN ANIMATION                           */
/*------------------------------------------------------------------------------*/

.logo {
    animation: fadeIn 5s ease-in-out infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

html {
    height: 100%;
    width: 100%;
    position: fixed;
}

body {
    background: linear-gradient(to bottom, #F5F5F5, rgba(0, 0, 0, 1));
    height: 100%;
}

.modern-container {
    background: rgba(0, 0, 0, 1);
}

.modern-input {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #555;
    color: #fff;
    padding: 4px 10px;
    border-radius: 8px;
    transition: border-color 0.3s;
}

.modern-input:focus {
    outline: none;
    border-color: #777;
}

.divider {
    width: 2px;
    height: 25px;
    margin: 0 15px;
    background-color: #444;
}

@media screen and (max-width: 768px) {
    .pos-sy {
        position: initial;
    }
    .table-container {
       
        th {
            position: initial;
        }
    }
   
}

/* Dashboard.css */

.dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    padding: 20px;
    grid-auto-rows: minmax(300px, auto);
    max-width: 100%;
    background-color: #f4f4f4;
}

.card {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

/* Header de la carte */
.card-header {
    font-size: 1.2em;
    font-weight: 800;
    color: #333;
    text-align: center;
    padding-bottom: 5px;
}

/* Contenu de la carte */
.card-body {
    flex-grow: 1;
}

/* Classes pour la taille des cartes (hauteur et largeur) */
.card.Wsmall {
    grid-column: span 1;
}

.card.Wmedium {
    grid-column: span 2;
}

.card.Wlarge {
    grid-column: span 3;
}

.card.Wxlarge {
    grid-column: span 4;
}

.card.Wxxlarge {
    grid-column: span 5;
}

.card.Hxsmall {
    grid-row: span 0.5;
}

.card.Hsmall {
    grid-row: span 1;
}

.card.Hmedium {
    grid-row: span 2;
}

.card.Hlarge {
    grid-row: span 3;
}

/* Responsive breakpoints */
@media (max-width: 768px) {
    .dashboard {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .card.Wsmall {
        grid-column: span 1;
    }

    .card.Wmedium {
        grid-column: span 2;
    }

    .card.Wlarge {
        grid-column: span 2;
    }

    .card.Wxlarge {
        grid-column: span 2;
    }
    .card.Wxlarge {
        grid-column: span 2;
    }

    .card.Hsmall {
        grid-row: span 1;
    }

    .card.Hmedium {
        grid-row: span 2;
    }

    .card.Hlarge {
        grid-row: span 2;
    }
}
